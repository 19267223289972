.editor {

  $size: 75vh;

  width: $size;
  height: $size;

  display: flex;
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255,255,255,0.1);
}