.editor-asset-gallery {

  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255,255,255,0.2);
  flex: 0;
  display: flex;
  flex-direction: row;

  ul.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;

    li {
      width: 2.5rem;
      writing-mode: vertical-lr;
      text-orientation: sideways;
      transform: rotate(-180deg);
      display: flex;
      cursor: pointer;
      justify-content: center;
      text-transform: uppercase;
      font-size: 0.85rem;
      font-weight: bold;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      color: black;
      border-bottom: 1px solid rgba(255,255,255,0.2);

      &:hover {
        background-color: rgba(255,255,255,0.5);
      }

      &.active {
        background-color: rgba(255,255,255, 1);
      }
    }


  }
}