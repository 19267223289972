.editor-info-table {

  th {
    font-size: 0.85rem;
    padding: 0;
  }

  td{
    font-size: 0.85rem;
  }

  tr {

    &:nth-of-type(even) td {
        background-color: rgba(255,255,255,0.35);
      }
    }
}