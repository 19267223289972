.editor-asset-list-item {

  width: 6rem;
  height: 6rem;
  border: 2px solid black;
  background-color: rgba(0,0,0,0.1);
  text-align: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0,100,200,0.35);

  .layer {
    display: flex;

    > svg {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    border: 2px solid rgba(255,0,0,1);
  }


  &.active {
    border-color: greenyellow;
    background-color: rgba(133, 200, 0, 0.2);
  }
}