.editor-screen {

  display: flex;
  align-items: stretch;
  flex: 1;
  position: relative;

  &__preview{
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
  }
}