.navigation {

  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255,255,255,0.1);

  display: flex;
  align-items: stretch;
  height: 3rem;
  padding: 0 2.5rem 0 2.5rem;
  justify-content: flex-start;

  &__list{
    display:flex;
    flex-grow: 1;
  }

  a, button {
    color: black;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    padding: 0 1rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bolder;
    cursor: pointer;
    transition: all linear 0.25s;
    flex: 0;
    flex-wrap: nowrap;
    line-break: normal;
    white-space: nowrap;

    &:hover {
      background-color: red;
      color: white;
    }

    &.active {
      background-color: white;

      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
}