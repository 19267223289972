.editor-info {


  display: flex;
  flex-grow: 0;
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255,255,255,0.2);
  padding: 1rem;
  overflow: hidden;
  flex-direction: column;
  max-width: 35rem;

}