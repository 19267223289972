.name-tag {


  background-color: black;
  color: white;
  bottom: 0;
  left: 0;
  font-size: 0.55rem;
  position: absolute;
  font-weight: bolder;
  padding: 0.15rem;
}