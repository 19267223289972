.gallery-item {

  $size: 20rem;

  background: blue;

  margin-left: 1rem;
  margin-bottom: 1rem;
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255,255,255,0.2);
  border-radius: 10px;
  overflow: hidden;

  &__image {
    width: $size;
    height: $size;
    background: rgba(255, 255, 255, 0.3);
    display: flex;
    position: relative;

    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }

    svg, img {
      flex: 1;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  &__options {
    display: flex;
  }

  &__title {
    font-size: 1.2em;
    font-weight: bold;
  }

  &__hashtags {
    font-weight: bolder;
    opacity: 0.35;
  }

  &__info {
    padding: 0.75rem;
    bottom: 0;
    left: 0;
    font-size: 0.75rem;
    flex-grow: 0;
  }

}