.color-combination-preview {
  $size: 18rem;


  box-sizing: border-box;
  width: $size;
  height: $size;

  > svg {
    border: 2px solid grey;
    width: 100%;
    height: 100%;
  }


}