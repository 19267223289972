.editor-asset-list {

  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255,255,255,0.1);
  background-color: white;
  width: 13.75rem;
  color: black;

  &__list {
    display: flex;
    padding-left: 0.5rem;
    flex-wrap: wrap;
    width: 13.5rem;

  }

  &__header {
    padding-left: 0.5rem;
  }

}