.gallery-list{

  padding: 1rem 2.5rem;



  &__title {
    padding-left: 1rem;
  }


  &__layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
  }

  &__search {
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__preview {

    position: relative;
    flex: 1;
    height: 100%;
    overflow: hidden;

    svg {
      position: relative;
      flex: 1;
      max-width: 100%;
      max-height: 100%;
    }
  }
}