$color1: #F3FFA0;
$color2: #631D13;
/**
Color
 */
$color3: #42021c;
$color4: #863d6e;
$black: #000;
$white: #fff;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

html{
  min-height: 100vh;
}


* {
  color: inherit;
  font-family: ".AppleSystemUIFont";
  box-sizing: border-box;
}

body, html, #root {
  background-color: $black;
  background-image: url("../assets/img/soft_background.webp");
  background-position: center center;
  background-size: cover;
  color: black;
  font-size: 16px;
  flex: 1;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
}

p {
  color: $color4
}


h1,
h2,
h3 {
  color: $color3;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2rem;
  margin-top: 4rem;
  line-height: 0;
}

input, textarea, select {
  border: 0;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  margin-bottom: 8px;
  padding: 12px;
  padding-left: 12px;
  padding-right: 12px;
  background: rgba(0,0,0,0.05);
  width: 100%;
  border: 2px solid transparent;

  &::placeholder {
    color: rgba(0,0,0,0.25);
  }

  &.error {
    border: 2px solid #ff0000;
    background: rgba(255,0,0,0.1)
  }
}

button {
  background: transparent;
  border: 1px solid rgba(255,255,255,0.1);
  color: inherit;
  width: 100%;
  font-weight: bolder;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.15s linear;

  &.compact {
    padding: 0.2rem;
  }

  &:disabled {
    cursor: auto;
  }

  &.danger {
    background-color: red;
    color: white;
  }

  &.warning {
    background-color: #ff9200;
    color: white;
  }


  &.positive {
    background-color: #8be78b;
    color: green;
  }

  &.locked {
    background-color: #9b9b9b;
    color: lightgrey;
  }

  &.fun {
    text-decoration-color: white !important;
    background: white url("../assets/img/fun_gradient.jpeg");
    background-size: cover;
    color: white;
    border: 1px solid rgb(255, 255, 255);
  }

  &.neutral {
    background-color: white;

    &:hover:not(*:disabled) {
      background: rgba(255,255,255,0.1);
    }

  }


}
//
//.error {
//  display: flex;
//  color: white;
//  text-align: center;
//  justify-content: center;
//  padding: 0.5rem;
//  margin-top: 1rem;
//  background-color: red;
//}

.error-message{

  color: red;
  font-size: 0.85rem;
  flex: 1;
  margin-bottom: 0.5rem;

}

.form {
  min-width: 300px;
}

.text {

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }
}

.align{

  &-right {
    justify-self: flex-end;
  }
}

.flex {
  display: flex;


  &--align-items-center{
    align-items: center;
  }
}

.dzu-dropzone{
  background-color: rgba(255,255,255,0.50);
  border: 5px dashed rgba(0,0,0,0.15);
  padding: 3rem;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.dzu-input{

  display: none;

}


.pressable {
  cursor: pointer;
}